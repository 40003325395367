import React from 'react'
import { Link } from 'gatsby'
import { format, getYear } from 'date-fns'

export default ({ posts }) => {
  const items = {}

  posts.forEach(post => {
    const key = getYear(new Date(post.node.frontmatter.date))

    const entry = (
      <Link
        className="py-2 cursor-pointer border-b border-dashed border-gray-400 flex"
        key={post.node.fields.slug}
        tag="li"
        to={post.node.fields.slug}
      >
        {post.node.frontmatter.title}
        <time className="ml-auto font-medium tracking-wider text-gray-700">
          {format(new Date(post.node.frontmatter.date), 'dd MMM')}
        </time>
      </Link>
    )

    items[key] = [...(items[key] || []), entry]
  })

  return Object.keys(items).map(label => (
    <div
      className="flex flex-col sm:flex-row items-start w-full max-w-4xl mx-auto"
      key={label}
    >
      <div className="mr-12 mb-4 sm:mb-0">
        <h2 className="pt-0 text-4xl">{label}</h2>
      </div>
      <ul className="w-full">{items[label]}</ul>
    </div>
  ))
}

import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Menu from '../components/Menu'
import Page from '../components/Page'
import PostList from '../components/PostList'

export default props => {
  const { title, subtitle, menu } = props.data.site.siteMetadata
  const posts = props.data.allMarkdownRemark.edges

  return (
    <Layout>
      <Helmet title={`Blog — ${title}`}>
        <meta name="description" content={subtitle} />
      </Helmet>
      <Menu data={menu} />
      <Page className="mx-auto sm:py-32">
        <PostList posts={posts} />
      </Page>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        author {
          name
        }
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { layout: { eq: "post" }, draft: { ne: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
      }
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            description
          }
        }
      }
    }
  }
`
